











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SummaryWidget extends Vue {
  /**
   * Props
   */
  @Prop() private net!: string;
  @Prop() private gross!: string;
  @Prop() private prefix?: string;

  /**
   * Getters
   */
  private get displayPrefix(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private get displayNetAppendix(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }
}
